/* src/styles/LoginPage.css */

.login-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1500px;
    margin: 0 auto;
    text-align: center;
}

.app-header{
  
        color: white;
        padding: 0.9rem;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
}

.app-footer {
   
    color: white;
    padding: 0.9rem;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.app-header h1
{
font-size: 1.3rem;
margin-bottom: 0px;
}
.app-footer p {
    margin: 0;
    font-size: 0.8rem;
}

.login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  
   
}

.login-title {
    margin-bottom: 1rem;
}

.login-form {
    width: 100%;
    max-width: 400px;
}

.form-control {
    margin-bottom: 5px;
    padding: 0.75rem !important;
}

/* .btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
} */

.login-footer {
    margin-top: 1rem;
}

.toggle-link {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #ff8800;
    text-decoration: underline;
}

.app-description {

    font-size: 0.7rem;
    color: #666;
}
.password-container {
    position: relative;
    width: 100%;
}



.btn-eye {
    position: absolute;
    right: 10px;
    width: 30px;
    color: #3b3a3a !important;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
    background: none !important;
    border: none !important;
    cursor: pointer;
    padding: 5px !important;
    font-size: 1.25rem;
}
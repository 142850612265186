@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-myapp {
  background-color: #01d6a4 !important;
}

.my-link {
  color: #E65100 !important;
  text-decoration: none;
}
.share-button {
  position: fixed;
  bottom: 95px;
  right: 20px;
  z-index: 2000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: #01ad85;
}
.share-text {
  position: fixed;
  bottom: 75px;
  right: 20px;
  z-index: 1000;
}
.share-button:hover {
  transform: scale(1.1);
  transition: transform 0.2s;
}
.home-footer-text
{
    position: fixed;
      bottom: 85px !important;
      width: 100% !important;
      z-index: 1000;
}


.header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  color: white;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.titlename {
  border-radius: 20px;
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  font-size: 20px;  
  color: white !important;
  text-align: left;
}
.titlename-recipe-ai {
  border-radius: 20px;
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  font-size: 20px;
  text-align: left;
  background-color: #edbf59;
  color: #000000 !important;
    
}




.forgot-password-container {
    max-width: 400px;
    margin: auto;
    padding: 10px;
    /* background-color: #f9f9f9; */
    border-radius: 5px;
   
}

h2 {
    text-align: center;
    color: #333;
}

.error-text {
    color: red;
    font-size: 0.9em;
}

.form-control {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

/* .btn {
    width: 100%;
    padding: 10px;
    background-color: #ffc107;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #e0a800;
} */
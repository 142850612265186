/* src/styles/MainApp.css */

.main-app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}



.app-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

.app-container {
    flex: 1;
    padding-top: 80px;
    /* To prevent content overlap with header */
    padding-bottom: 60px;
    /* To prevent content overlap with footer */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: calc(100vh - 120px);
    /* Total height minus header and footer */
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-icon-link {
    text-decoration: none;
    text-align: center;
}

.app-icon {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.app-text {
    margin-top: 8px;
    font-size: 14px;
    color: #000;
}
